import React from "react";
import {graphql} from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import {mapEdgesToNodes} from "../lib/helpers";
import About from "../components/about/about";

export const query = graphql`
  query AboutPageQuery {
    settings: allSanitySiteSettings {
      edges {
        node {
          author {
            image {
              alt
              asset {
                gatsbyImageData(width: 500, height: 500, placeholder: BLURRED)
              }
            }
            _rawBio
          }
        }
      }
    }
  }
`;

const AboutPage = props => {
  const {data, errors} = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    );
  }
  const aboutData = data && data.settings && mapEdgesToNodes(data.settings);
  return (
    <Layout>
      <SEO title="About"/>
      <About about={aboutData[0]}/>
    </Layout>
  );
};

export default AboutPage;
