import React from "react";

import {GatsbyImage, getImage} from "gatsby-plugin-image"

import BlockText from "../block-text";
import Container from "../container/container";

import {responsiveTitle1} from "../typography.module.css";
import * as styles from "./about.module.css";

const About = ({about}) => {
  return (
    <Container>
      <h1 className={responsiveTitle1}>About</h1>
      <div className={styles.row}>
        {about && about.author.image && (
          <GatsbyImage
            image={getImage(about.author.image.asset.gatsbyImageData)}
            alt={about.author.image.alt}
            className={styles.left}
          />
        )}
        <div className={styles.right}>
          {about && about.author._rawBio && <BlockText blocks={about.author._rawBio}/>}
        </div>
      </div>
    </Container>
  );
};

export default About;
